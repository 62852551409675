<template>
  <fullscreen class="mirror h-100" ref="fullscreen" background="#8bacbd">
    <div class="logo-wrapper position-absolute text-center text-white cursor-pointer">
      <div class="logo-content bg-white rounded-circle border border-secondary">
        <img src="../../assets/tornhuset.png" class="logo m-1 rounded-circle" alt="Agnes">
      </div>
    </div>

    <div class="mirror-container d-flex flex-column h-100 w-100 align-items-center justify-content-center">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <div class="card rounded-pill" :class="{ 'bg-danger': beRed }">
              <div class="card-body">
                <mirror-scan :log="latestLog" :on-state-changed="stateChanged" v-if="latestLog"></mirror-scan>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fullscreen>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"
  import orderBy from "lodash/orderBy"

  import MirrorScan from "@/frontend/components/mirror-scan.vue"

  export default {
    name: "mirror",
    components: { MirrorScan },
    data() {
      return {
        logs: [],
        currentState: "waiting"
      }
    },
    computed: {
      sortedLogs() {
        return orderBy(this.logs, ["when"], ["desc"])
      },
      latestLog() {
        return this.sortedLogs[0]
      },
      latestLogFailed() {
        if(this.latestLog) {
          return (typeof this.latestLog.failed == "boolean" && this.latestLog.failed == true)
        } else {
          return false
        }
      },
      beRed() {
        if (this.latestLogFailed && this.currentState !== "waiting") {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      stateChanged(state) {
        this.currentState = state
      }
    },
    async mounted() {
      let date = this.$DateTime.local().toFormat("yyyy-MM-dd")
      await this.$bind("logs", firebase.firestore().collection(`logs`).where("when", ">=", date).where("what", "==", "used_card"))
    }
  }
</script>

<style lang="scss" scoped>
  .mirror {
    background-color: #8bacbd;
  }

  .mirror-container {
    padding: 0px 120px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .logo-wrapper {
    top: 20px;
    left: 20px;
    text-transform: uppercase;
    font-weight: 400;

    .logo-content {
      border-width: .2rem !important;
    }

    .logo {
      max-width: 53px;
    }
  }
</style>
<template>
  <span class="mirror-scan">
    <div class="loading text-center" v-if="state == 'loading'">
      <font-awesome-icon icon="spinner" size="4x" spin fixed-width />
      <h1 class="mt-2 mb-0 animate-flicker">
        Laddar
      </h1>
    </div>

    <div class="waiting text-danger text-center" v-else-if="state == 'closed'">
      <font-awesome-icon icon="exclamation-triangle" size="4x" fixed-width />
      <h1 class="mt-2 mb-0">STÄNGT</h1>
    </div>

    <div class="waiting text-center" v-else-if="state == 'waiting'">
      <font-awesome-icon icon="id-card" size="4x" fixed-width />
      <h1 class="mt-2 mb-0">
        {{ scanPhrase }}
      </h1>
    </div>

    <div class="waiting text-center" v-else-if="state == 'success'">
      <h1>Senaste kl. {{ when }}</h1>
      <h2 class="font-weight-normal mb-0">{{ person.fullName }}</h2>

      <div v-if="failureLog">
        <h2 class="m-0">
          {{ log.message }}
        </h2>
      </div>
      <div v-else>
        <h3 class="font-weight-normal">
          <span v-if="log.half">Halv, </span>
          <span v-if="log.toGo">Hämta, </span>
          {{ portionsMessage }}, {{ vouchersDrawnMessage }}
        </h3>
        <h3 class="m-0">
          {{ voucherCountMessage }}
        </h3>
      </div>
    </div>
  </span>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"

  export default {
    name: "mirror-scan",
    props: {
      log: {
        type: Object,
        required: true
      },
      onStateChanged: {
        type: Function,
        default: () => {}
      },
      scanPhrase: {
        type: String,
        default: "Väntar"
      },
      portionsPhrase: {
        type: String,
        default: "%{portions}"
      },
      voucherDrawnPhrase: {
        type: String,
        default: "%{voucherCost}"
      },
      voucherCountPhrase: {
        type: String,
        default: "%{voucherCount} kvar"
      },
    },
    data() {
      return {
        state: "waiting",
        person: null,
        timeout: null,
        schedules: []
      }
    },
    computed: {
      failureLog() {
        return (typeof this.log.failed == "boolean" && this.log.failed == true)
      },
      when() {
        return this.$DateTime.fromISO(this.log.when).toFormat("HH:mm:ss")
      },
      coupons() {
        let result = "n/a"
        if(this.schedules.length > 0) {
          let currentSchedule = this.schedules[0]
          let when = this.$DateTime.fromISO(this.log.when)
          let schedule = currentSchedule.days[when.weekday]
          let portions = 1

          if(this.log.portions) {
            portions = parseInt(this.log.portions)
          }

          if(this.log.half) {
            result = (1 * portions)
          } else {
            result = (parseInt(schedule.coupons) * portions)
          }
        }

        return result
      },
      voucherCount() {
        let voucherCount = 0

        if(this.person) {
          if(this.person.vouchers && this.person.vouchers.length > 0) {
            if(this.person.vouchers.length > 1) {
              let voucherAmounts = this.person.vouchers.map((voucher) => voucher.amount)
              voucherCount = voucherAmounts.reduce((initial, voucher) => parseInt(initial) + parseInt(voucher))
            } else {
              voucherCount = this.person.vouchers[0].amount
            }
          }
        }
        
        return `${voucherCount} ${voucherCount == 1 ? "kupong" : "kuponger"}`
      },
      portionsMessage() {
        let portions = 1
        if(this.log.portions) { portions = parseInt(this.log.portions) }

        let phrase = `${portions} ${portions == 1 ? 'portion' : 'portioner'}`
        return this.portionsPhrase.replace("%{portions}", phrase)
      },
      vouchersDrawnMessage() {
        let phrase = `${this.coupons} ${this.coupons == 1 ? "kupong" : "kuponger"}`
        return this.voucherDrawnPhrase.replace(`%{voucherCost}`, phrase)
      },
      voucherCountMessage() {
        return this.voucherCountPhrase.replace(`%{voucherCount}`, this.voucherCount)
      }
    },
    methods: {
      setState(state) {
        this.state = state
        this.onStateChanged(state)
      },
      stateWaiting() {
        this.setState("waiting")
      },
      async getPerson() {
        // if (this.timeout) {
        //   this.setState("waiting")
        //   clearTimeout(this.timeout)
        // }

        this.setState("loading")
        let personGet = await firebase.firestore().collection(`people`).doc(this.log.to).get()
        this.person = await personGet.data()
        this.setState("success")

        // this.timeout = setTimeout(this.stateWaiting, 10000);
      }
    },
    async mounted() {
      this.setState("loading")
      await this.getPerson()
      await this.$bind("schedules", firebase.firestore().collection(`schedules`).where("year", "==", this.$DateTime.local().year.toString()))

      const now = this.$DateTime.local()

      if (this.schedules.length == 0) {
        this.setState("closed")
      } else {
        const schedule = this.schedules[0]
        let todaysSchedule = Object.values(schedule.days).find((scheduleDay) => scheduleDay.day == now.toFormat("yyyy-MM-dd"))
        if(!todaysSchedule) {
          todaysSchedule = schedule.days[now.weekday]
        }

        if(!todaysSchedule || !todaysSchedule.active) {
          this.setState("closed")
        }
      }
    },
    watch: {
      log: function(newValue, oldValue) {
        if(newValue.id != oldValue.id) {
          this.getPerson()
        }
      }
    }
  }
</script>